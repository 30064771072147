
import { defineComponent } from "vue";
import AdminPanelNavMenu from "@/components/molecules/AdminPanelNavMenu.vue";
import View from "@/assets/svg/view.svg?inline";
import Edit from "@/assets/svg/edit.svg?inline";
import Delete from "@/assets/svg/delete.svg?inline";
import Search from "@/assets/svg/search.svg?inline";
import Picture from "@/assets/test/picture.svg?inline";
import DefaultPopup from "@/components/molecules/DefaultPopup.vue";
import SortArrow from "@/assets/svg/sort-arrow.svg?inline";
import AddInstructor from "@/assets/svg/add-instructor.svg?inline";
import Pagination from "@/components/molecules/Pagination.vue";
import vClickOutside from "click-outside-vue3";
import Calendar from "@/components/molecules/Calendar.vue";
import CalendarOneDate from "@/components/molecules/OneDateCalendar.vue";
import SelectArrow from "@/assets/svg/select-arrow.svg?inline";
import CreateStudent from "@/assets/svg/create-student-name.svg?inline";
import EmailIcon from "@/assets/svg/create-student-email-icon.svg?inline";
import PhoneIcon from "@/assets/svg/login-phone-icon.svg?inline";
import UploadPhoto from "@/assets/svg/upload-photo.svg?inline";
import PhotoMock from "@/assets/svg/photo-zaglushka.svg?inline";
import Pluse from "@/assets/svg/pluse-blue.svg?inline";
import Minuse from "@/assets/svg/minuse-blue.svg?inline";
import AlertBlock from "@/components/molecules/AlertBlock.vue";
import authHeader from "@/services/auth-header";
import DateRangePicker from "@/components/molecules/DateRangePicker.vue";
import { Form, Field } from "vee-validate";

export default defineComponent({
  name: "Students",
  data() {
    return {
      page: 1,
      studentsMeta: null,
      studentsData: {},
      instructorsData: {},
      attachInstructorsData: {},
      inviteInstructorsData: [],
      errorstudents: null,
      isModalChooseInstructorNewStudent: false,
      isModalCopyCode: false,
      searchData: "",
      searchDataInstructors: "",
      sortData: "",
      instructorName: "",
      selectedNewDate: null,
      selectedNewDateSubsqribe: null,
      isCalendarVisible: false,
      isCalendarSubsqribeVisible: false,
      showSubscription: false,
      subscriptionEnds: false,
      showNoSubscription: false,
      withtoutInstructor: false,
      justParents: false,
      copied: false,
      errorAttachInstructor: null,
      activeStep: "1",
      isPreview: false,
      errorInstructor: null,
      fullName: "",
      phone: null,
      email: null,
      studentData: {},
      additionalStudentData: ["1", "2"],
      isAdditional: false,
      studentParentId: 0,
      expandedRows: {},
      newStudentId: "",
      studentSetPasswordLink: null,
      showJustParentData: false,
      showWithDeactivationDate: false,
      filtersDate: "",
      period: 1,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    AdminPanelNavMenu,
    View,
    Edit,
    Delete,
    Search,
    Picture,
    DefaultPopup,
    SortArrow,
    Pagination,
    Calendar,
    CalendarOneDate,
    AddInstructor,
    SelectArrow,
    Form,
    Field,
    CreateStudent,
    EmailIcon,
    PhoneIcon,
    UploadPhoto,
    AlertBlock,
    PhotoMock,
    Pluse,
    Minuse,
    DateRangePicker,
  },
  watch: {
    searchData() {
      this.getStudents();
      this.page = 1;
    },
    searchDataInstructors() {
      this.getInstructors();
      this.page = 1;
    },
  },
  mounted() {
    this.getStudents();
    this.getInstructors();
  },
  computed: {
    filteredStudents() {
      // if (this.showJustParentData) {
      //   return this.studentsData.filter(
      //     (student) => student.children && student.children.length > 0
      //   );
      // }
      return this.studentsData;
    },
  },
  methods: {
    getStudents(params, sort) {
      let searchParam = `?page=${this.page}&search=`;
      if (sort === undefined) {
        sort = "";
      }
      if (this.searchData !== "") {
        searchParam = `?page=${this.page}&search=` + this.searchData;
      }
      if (sort === undefined) {
        sort = "id";
      }
      params = searchParam + "&sort=" + sort + this.filtersDate;
      this.$store.dispatch("students/getStudents", params).then(
        (res) => {
          this.studentsData = res.data;
          this.studentsMeta = res.meta;
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    getInstructors(params) {
      params = "?search=" + this.searchDataInstructors;
      this.$store.dispatch("instructors/getInstructors", params).then(
        (res) => {
          this.instructorsData = res.data;
          this.instructorsMeta = res.meta;
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    viewStudent(id) {
      this.$router.push("/admin/student?student=" + id);
    },
    editstudent(id) {
      this.$router.push("/admin/edit-student?student=" + id);
    },
    openPopupChoosenInstructorNewStudent(id) {
      this.studentId = id;
      this.isModalChooseInstructorNewStudent = true;
    },
    handleCloseModalChooseInstructorNewStudent() {
      this.activeStep = "1";
      this.fullName = "";
      this.email = "";
      this.phone = "";
      this.isPreview = false;
      this.isModalChooseInstructorNewStudent = false;
    },
    openPopupCopyCode() {
      this.isModalCopyCode = true;
    },
    handleCloseModalCopyCode() {
      this.isModalCopyCode = false;
    },
    isCheckedInstructor(id) {
      return this.attachInstructorsData?.some(
        (instructor) => instructor.id === id
      );
    },
    attachInstructors(id) {
      if (!Array.isArray(this.attachInstructorsData)) {
        this.attachInstructorsData = [];
      }
      const index = this.attachInstructorsData.findIndex(
        (instructor) => instructor.id === id
      );
      if (index !== -1) {
        this.attachInstructorsData.splice(index, 1);
      } else {
        const instructorToAdd = this.instructorsData?.find(
          (instructor) => instructor.id === id
        );
        if (instructorToAdd) {
          this.attachInstructorsData.push(instructorToAdd);
        }
      }
      const data = {
        id: this.studentId,
        instructors: [id],
      };
      this.$store.dispatch("students/attachInstructors", data).then(
        () => {
          this.getStudents();
        },
        (error) => {
          this.errorAttachInstructor =
            error.response?.data?.message || "Error attaching instructor";
        }
      );
    },
    unAttachInstructors(id) {
      const index = this.attachInstructorsData.findIndex(
        (instructor) => instructor.id === id
      );
      if (index !== -1) {
        this.attachInstructorsData.splice(index, 1);
      } else {
        const instructorToAdd = this.instructorsData.find(
          (instructor) => instructor.id === id
        );
        if (instructorToAdd) {
          this.attachInstructorsData.push(instructorToAdd);
        }
      }
      const ids = [];
      ids.push(id);
      const data = {
        id: this.studentId,
        instructors: ids,
      };
      this.$store.dispatch("students/detachInstructors", data).then(
        () => {
          this.getStudents();
        },
        (error) => {
          this.errorAttachInstructor = error.response.data.message;
        }
      );
    },
    handlePage(page) {
      this.page = page;
      this.getStudents();
    },
    isChecked(id) {
      if (this.instructorName) {
        return this.instructorName.some((selected) => selected.id === id);
      }
    },
    async copyText() {
      try {
        await navigator.clipboard.writeText(this.studentSetPasswordLink);
        this.copied = true;
        setTimeout(() => (this.copied = false), 3000);
      } catch (error) {
        console.error("Failed to copy text: ", error);
      }
    },
    selectInstructors(id) {
      this.inviteInstructorsData.push(id);
    },
    formatDate(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      const day = date.getDate();
      const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
        date
      );
      const year = date.getFullYear();
      return `${day} ${month} ${year}`;
    },
    selectDateFill(date, month, year) {
      if (!date.value) return;

      const selectedDate = new Date(year, month, Number(date.value));
      const currentDate = new Date();

      if (selectedDate > currentDate) {
        return;
      }

      const formattedDate =
        selectedDate.getFullYear() +
        "-" +
        String(selectedDate.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(selectedDate.getDate()).padStart(2, "0");

      if (this.selectedStartDate && this.selectedEndDate) {
        this.selectedStartDate = null;
        this.selectedEndDate = null;
      }

      if (!this.selectedStartDate) {
        this.selectedStartDate = formattedDate;
      } else {
        this.selectedEndDate = formattedDate;
      }

      if (this.selectedStartDate && this.selectedEndDate) {
        this.filtersDate = `&date_from=${this.selectedStartDate}&date_to=${this.selectedEndDate}`;
        this.getStudents();
        this.isCalendarVisible = false;
      }
    },
    selectDateFillSubsqribe(date, month, year) {
      const selectedDate = new Date(year, month, date.value);
      const Month = selectedDate.getMonth() + 1;
      let monthFormat = "";
      if (Month < 10) {
        monthFormat = "0" + (selectedDate.getMonth() + 1);
      } else monthFormat = (selectedDate.getMonth() + 1).toString();
      const Day = selectedDate.getDate();
      let dayFormat = "";
      if (Day < 10) {
        dayFormat = "0" + selectedDate.getDate();
      } else dayFormat = selectedDate.getDate().toString();
      this.selectedNewDateSubsqribe =
        selectedDate.getFullYear() + "-" + monthFormat + "-" + dayFormat;
      this.filtersDate = `&date_of_subscription_expiration=${this.selectedNewDateSubsqribe}`;
      this.getStudents();
      this.isCalendarSubsqribeVisible = false;
    },
    toggleCalendar() {
      this.isCalendarVisible = !this.isCalendarVisible;
    },
    closeCalendar() {
      this.isCalendarVisible = false;
    },
    toggleCalendarSubsqribe() {
      this.isCalendarSubsqribeVisible = !this.isCalendarSubsqribeVisible;
    },
    closeCalendarSubsqribe() {
      this.isCalendarSubsqribeVisible = false;
    },
    resetFilters() {
      window.location.reload();
    },
    checkedShowSubscription() {
      const filters = [];
      if (this.subscriptionEnds) filters.push("&subscription_ends=1");
      if (this.showSubscription) filters.push("&show_only_no_subscription=1");
      if (this.showNoSubscription) filters.push("&show_no_subscription=1");
      if (this.withtoutInstructor) filters.push("&without_instructor=1");
      if (this.showJustParentData) filters.push("&just_parents=1");
      if (this.showWithDeactivationDate)
        filters.push("&with_deactivation_date=1");

      this.filtersDate = filters.join("");
      this.getStudents();
    },
    nextStep(step) {
      this.activeStep = step;
      this.errorInstructor = null;
    },
    prewStep(step) {
      this.activeStep = step;
    },
    createStudentStep(step) {
      this.$store
        .dispatch("students/getLinkSetPassword", this.newStudentId)
        .then(
          (res) => {
            this.activeStep = step;
            this.studentSetPasswordLink = res.set_password_link;
          },
          (error) => {
            this.errorAttachInstructor = error.response.data.message;
          }
        );
    },
    handleFileUpload() {
      this.isPreview = false;
      this.imagePreview = null;
      this.errorInstructor = null;
      this.file = this.$refs.file.files[0];
      const formData = new FormData();
      formData.append("thumbnail", this.file);
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          this.imagePreview = reader.result;
          this.isPreview = true;
        }.bind(this),
        false
      );
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
    showAdditionalStudent(id) {
      this.expandedRows = {
        ...this.expandedRows,
        [id]: true,
      };
    },
    hideAdditionalStudent(id) {
      this.expandedRows = {
        ...this.expandedRows,
        [id]: false,
      };
    },
    isRowExpanded(id) {
      return !!this.expandedRows[id];
    },
    createStudent() {
      const API_URL = process.env.VUE_APP_URL;
      const formData = new FormData();
      formData.append("full_name", this.fullName);
      formData.append("phone", this.phone);
      formData.append("email", this.email);
      if (this.inviteInstructorsData.length > 0) {
        formData.append("instructor_ids[]", this.inviteInstructorsData);
      }
      if (this.file !== null) {
        formData.append("image", this.file);
      }
      this.axios
        .post(
          API_URL +
            `admin/schools/${JSON.parse(
              localStorage.getItem("admin-school-id")
            )}/students`,
          formData,
          {
            headers: authHeader(),
          }
        )
        .then(
          (res) => {
            this.studentData = res.data.data;
            this.newStudentId = this.studentData.id;
            this.nextStep("3");
          },
          (error) => {
            this.errorInstructor = error.response.data.errors;
            this.serverError = error.response.data;
          }
        );
    },
    showJustParent() {
      this.showJustParentData = !this.showJustParentData;
    },
    selectStaticRange(period) {
      this.period = period;
      let startDate = "";
      let endDate = "";
      const currentDate = new Date();
      const pastDate = new Date();
      if (this.period === 1) {
        pastDate.setDate(currentDate.getDate() - 7);
      } else if (this.period === 2) {
        pastDate.setDate(currentDate.getDate() - 30);
      } else if (this.period === 3) {
        pastDate.setDate(currentDate.getDate() - 1000);
      }

      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };
      startDate =
        this.selectedStartDate !== undefined
          ? this.selectedStartDate
          : formatDate(pastDate);
      endDate =
        this.selectedEndDate !== undefined
          ? this.selectedEndDate
          : formatDate(currentDate);
      this.filtersDate = `&date_from=${startDate}&date_to=${endDate}`;
      this.getStudents();
    },
  },
});
